<template>
  <div class="login-page">
    <v-container>
      <v-row justify="center" class="mt-16">
        <v-col cols="12" lg="6">
          <div class="text-center mb-5">
            <img src="@/assets/logo.svg" width="200" /><br />
          </div>

          <v-card flat class="rounded-lg">
            <form
              @submit.prevent="validate"
              v-if="!validated || phoneConfirmed"
            >
              <v-card-text class="pa-6 pa-lg-12">
                <h1 class="text-center mb-lg-8 mb-5">Information du compte</h1>
                <v-alert
                  dense
                  v-if="error"
                  color="red"
                  dark
                  icon="mdi-information-outline"
                  border="left"
                  prominent
                >
                  {{ error }}
                </v-alert>

                <!--  pos_name -->
                <v-text-field
                  v-if="form.type == 'salepoint'"
                  outlined
                  prepend-inner-icon="mdi-store"
                  type="text"
                  v-model="form.pos_name"
                  label="Nom de point de vente *"
                  hide-details=""
                  :color="errors && errors.pos_name ? 'red' : 'primary'"
                  :class="errors && errors.pos_name ? 'redBorder' : ''"
                  @input="
                    errors && errors.pos_name ? (errors.pos_name = '') : false
                  "
                >
                </v-text-field>
                <p class="red--text" v-if="form.type == 'salepoint'">
                  {{ errors.pos_name ? errors.pos_name[0] : "" }}
                </p>
                <!-- End pos_name -->

                <!--  name -->
                <v-text-field
                  outlined
                  prepend-inner-icon="mdi-account-outline"
                  type="text"
                  v-model="form.name"
                  label="Nom Complet *"
                  hide-details=""
                  :color="errors && errors.name ? 'red' : 'primary'"
                  :class="errors && errors.name ? 'redBorder' : ''"
                  @input="
                    errors && errors.name ? (errors.name = '') : false
                  "
                >
                </v-text-field>
                <p class="red--text">
                  {{ errors.name ? errors.name[0] : "" }}
                </p>
                <!-- End name -->

                <v-row>
                  <v-col cols="12" md="3">
                    <v-select
                      outlined
                      label="Pays *"
                      item-text="name"
                      :loading="isCountriesLoading"
                      item-value="id"
                      :items="countries"
                      v-model="form.country_id"
                      @change="countryChanged"
                      hide-details=""
                      :color="errors && errors.country_id ? 'red' : 'primary'"
                      :class="errors && errors.country_id ? 'redBorder' : ''"
                      @input="
                        errors && errors.country_id
                          ? (errors.country_id = '')
                          : false
                      "
                    >
                      <template v-slot:item="slotProps">
                        <div class="d-flex align-center justify-center">
                          <div style="width: 50px">
                            <img
                              :src="getImageLink(slotProps.item.flag)"
                              max-height="25"
                              max-width="25"
                              width="25"
                              height="25"
                              class="p-2"
                            />
                          </div>

                          {{ slotProps.item.name }}
                        </div>
                      </template>
                    </v-select>
                    <p class="red--text">
                      {{ errors.country_id ? errors.country_id[0] : "" }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="9">
                    <!--  phone -->
                    <v-text-field
                      outlined
                      prepend-inner-icon="mdi-phone-outline"
                      type="phone"
                      v-model="form.phone"
                      label="Téléphone *"
                      :prefix="phoneCode + ''"
                      hide-details=""
                      :color="errors && errors.phone ? 'red' : 'primary'"
                      :class="errors && errors.phone ? 'redBorder' : ''"
                      @input="
                        errors && errors.phone ? (errors.phone = '') : false
                      "
                      :disabled="phoneConfirmed"
                    >
                    </v-text-field>
                    <p class="red--text">
                      {{ errors.phone ? errors.phone[0] : "" }}
                    </p>
                    <!-- End phone -->
                  </v-col>
                </v-row>

                <!--  email -->
                <v-text-field
                  outlined
                  prepend-inner-icon="mdi-email-outline"
                  type="email"
                  v-model="form.email"
                  label="Email (Optionnel)"
                  :color="errors && errors.email ? 'red' : 'primary'"
                  :class="errors && errors.email ? 'redBorder' : ''"
                  @input="errors && errors.email ? (errors.email = '') : false"
                  hide-details=""
                >
                </v-text-field>
                <p class="red--text">
                  {{ errors.email ? errors.email[0] : "" }}
                </p>
                <!-- End email -->

                <v-text-field
                  outlined
                  prepend-inner-icon="mdi-lock-outline"
                  v-model="form.password"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  label="Mot de passe *"
                  :color="errors && errors.password ? 'red' : 'primary'"
                  :class="errors && errors.password ? 'redBorder' : ''"
                  hide-details=""
                >
                </v-text-field>
                <p class="red--text">
                  {{ errors.password ? errors.password[0] : "" }}
                </p>

                <v-text-field
                  outlined
                  prepend-inner-icon="mdi-lock-outline"
                  v-model="form.password_confirmation"
                  :errors-messages="errors ? errors.password_confirmation : ''"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  label="Confirmez votre Mot de passe *"
                  :color="
                    errors && errors.password_confirmation ? 'red' : 'primary'
                  "
                  :class="
                    errors && errors.password_confirmation ? 'redBorder' : ''
                  "
                  hide-details=""
                >
                </v-text-field>
                <p class="red--text">
                  {{ errors.password ? errors.password[0] : "" }}
                </p>
                <v-checkbox
                  label="Se souvenir de moi"
                  style="margin: -10px"
                ></v-checkbox>
                <v-btn
                  type="submit"
                  depressed
                  :loading="btnLoading"
                  large
                  block
                  color="primary"
                  class="rounded-lg mt-3 mb-5"
                >
                  S'Inscrire
                </v-btn>

                <v-divider />

                <div class="text-center mt-4">
                  Déjà un membre?
                  <router-link to="/login" class="text-decoration-none"
                    >S'identifier
                  </router-link>
                </div>
              </v-card-text>
            </form>

            <div v-else>
              <FormOtp
                number="+213669767470"
                @phoneConfirmed="handlePhoneConfirmed($event)"
              />
              <v-card-actions class="px-5 mt-0">
                <v-btn @click="validated = false" class="rounded-lg default mb-5" block large elevation="0">
                  <v-icon class="mr-4">mdi-arrow-left-thick</v-icon> Modifier Mon numéro
                </v-btn>
              </v-card-actions>
              
            </div>
          </v-card>
          <p class="text-center white--text mt-10">
            © Copyright {{ new Date().getFullYear() }}
            <strong class="text-primary">Gifty</strong> Tous droits réservés.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
    
<script>
import axios from "axios";
import FormOtp from "./FormOtpGifty.vue";

export default {
  components: { FormOtp },
  data() {
    return {
      // Loadings
      btnLoading: false,
      isCountriesLoading: false,
      isWilayasLoading: false,
      // form
      error: null,
      errors: {},
      form: {
        source: "web",
        token: "",
        country_id: 1
      },
      show: false,

      phoneCode: "+213",

      // countries
      countries: [],

      types: [
        {
          id: "user",
          name: "Particulier",
          icon: "mdi-account",
        },
        {
          id: "salepoint",
          name: "Professionel",
          icon: "mdi-store",
        },
      ],

      validated: false,
      phoneConfirmed: false,
    };
  },
  methods: {
    validate() {
      this.btnLoading = true;
      this.errors = {};
      axios
        .post(process.env.VUE_APP_BASE_URL + "v1/multistep/register/validate", {
          ...this.form,
          phone: this.phoneCode + this.form.phone,
        })
        .then((res) => {
          if(res.data.data.otp_enabled == true){
            this.validated = true;
          }else{
            this.register();
          }
          
          this.btnLoading = false;
        })
        .catch((err) => {
          this.btnLoading = false;
          this.errors = err.response.data.errors;
          this.error = "Une erreur s'est produite veuillez corriger.";
          console.log(this.errors);
        });
    },
    register() {
      this.btnLoading = true;

      this.errors = {};
      axios
        .post(process.env.VUE_APP_BASE_URL + "v1/multistep/register", {
          ...this.form,
          phone: this.phoneCode + this.form.phone,
        })
        .then((res) => {
          this.$store.dispatch("isLoggedIn", true);
          this.$store.dispatch("user", res.data);
          this.$store.dispatch("stores", []);
          this.btnLoading = false;
          this.$router.go(0);
        })
        .catch((err) => {
          this.btnLoading = false;
          this.errors = err.response.data.errors;

          this.error = "Une erreur s'est produite veuillez corriger.";
          console.log(this.errors);
        });
    },

    getCountries() {
      this.isCountriesLoading = true;
      axios
        .get(process.env.VUE_APP_BASE_URL + "v1/countries")
        .then((res) => {
          this.isCountriesLoading = false;
          this.countries = res.data.data;
        })
        .catch((err) => {
          this.isCountriesLoading = false;
          console.log(err);
        });
    },
    countryChanged() {
      var id = this.form.country_id;
      var country = this.countries.find((x) => x.id === id);

      this.phoneCode = "+" + country.phonecode;
    },

    getWilayas() {
      this.isWilayasLoading = true;
      axios
        .get(process.env.VUE_APP_BASE_URL + "wilaya/all")
        .then((res) => {
          this.isWilayasLoading = false;
          this.wilayas = res.data;
        })
        .catch((err) => {
          this.isWilayasLoading = false;
          console.log(err);
        });
    },
    getCommunes() {},

    handlePhoneConfirmed(event) {
      //this.form.phone = event.number;
      this.form.token = event.token;
      this.phoneConfirmed = true;
      this.register();
    },
  },

  mounted() {
    if (!this.countries.length) {
      this.getCountries();
    }
  },
};
</script>

<style scoped>
.redBorder >>> fieldset {
  border-color: rgba(250, 0, 33, 0.986);
}
</style>
    