<template>
  <div>
    <form @submit.prevent="sendCode" ref="confirmAccount" v-if="!sms_sent">
      <v-card-text class="pt-6 pa-lg-12 pb-0">
        <v-alert />
        <h3 class="text-center">
          Nous vous enverrons un code à votre Téléphone
        </h3>

        <v-alert
          dense
          v-if="sendError"
          color="red"
          dark
          icon="mdi-information-outline"
          border="left"
          prominent
          class="mt-4"
        >
          {{ sendError }}
        </v-alert>

        <v-text-field
          outlined
          prepend-inner-icon="mdi-phone-outline"
          type="phone"
          v-model="number"
          label="Téléphone *"
          prefix=""
          hide-details=""
          class="mt-5"
          disabled
        >
        </v-text-field>

        
      </v-card-text>
      <v-card-actions class="flex-column pa-6 pt-0 pb-0">
        <v-btn
          type="submit"
          depressed
          :loading="btnLoading"
          large
          block
          color="primary"
          class="rounded-lg"
        >
          Envoyer le code
        </v-btn>
      </v-card-actions>
    </form>

    <form v-if="sms_sent && !phoneConfirmed" :disabled="checkLoading">
      <v-card-text class="pa-6 pa-lg-12 justify-center pb-0">
        <h3 class="text-center mb-lg-8 mb-4">
          Confirmation de votre Telephone
        </h3>
        <v-alert
          dense
          v-if="checkError"
          color="red"
          dark
          icon="mdi-information-outline"
          border="left"
          prominent
          class="mt-4 mb-4"
        >
          {{ checkError }}
        </v-alert>

        <p class="d-flex justify-center">
          <OtpInput
            ref="otpInput"
            input-classes="otp-input"
            separator="-"
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-change="handleOnChange"
            @on-complete="handleOnComplete"
          />
        </p>

        <v-btn
          depressed
          large
          block
          color=""
          class="rounded-lg"
          @click="resendCode"
        >
        <v-icon class="mr-2">mdi-email-arrow-right</v-icon>
          Renvoyer Le code
        </v-btn>

        <p class="text-center">
          <v-progress-circular
            color="primary"
            indeterminate
            v-if="checkLoading"
          >
          </v-progress-circular>
        </p>
      </v-card-text>
    </form>
  </div>
</template>

<script>
// FireBase
import OtpInput from "@bachdgvn/vue-otp-input";
import axios from "axios";

export default {
  components: {
    OtpInput,
  },

  props: ["number"],

  data() {
    return {
      sendCodeLoading: false,
      form: {
        number: this.number,
        token: "",
      },
      btnLoading: false,

      sms_sent: false,
      checkLoading: false,
      sendError: false,
      code_error: false,
      checkError: "",
      phoneConfirmed: false,
    };
  },
  methods: {

    resendCode(){
      this.sms_sent = false;
      this.sendCode();
    },
    sendCode() {
      this.sendError = "";
      this.btnLoading = true;
      axios
        .post(process.env.VUE_APP_BASE_URL + "v1/otp/send", {
          phone: this.form.number,
          action: 'register'
        })
        .then((res) => {
          var token = res.data.data.token;
          this.form.token = token;
          //this.$emit("phoneConfirmed", this.form);
          this.sms_sent = true;
          this.btnLoading = false;
        })
        .catch((err) => {
          this.btnLoading = false;
          this.sendError = err.response.data.message;
          console.log(err);
        });
    },

    checkCode(code) {
      this.checkLoading = true;
      this.checkError = "";
      axios
        .post(process.env.VUE_APP_BASE_URL + "v1/otp/check", {
          token: this.form.token,
          code: code,
        })
        .then((res) => {
          var token = res.data.data.token;
          this.form.token = token;
          this.$emit("phoneConfirmed", this.form);
          this.btnLoading = false;
          this.checkLoading = false;
        }).catch((err) => {
          this.btnLoading = false;
          this.checkLoading = false;
          this.checkError = err.response.data.message;
        });
    },

    // Otp Input
    handleOnComplete(value) {
      //console.log("OTP completed: ", value);
      this.checkCode(value);
    },
    handleOnChange(value) {
      //console.log("OTP changed: ", value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
  },
};
</script>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  color: rgba(214, 1, 72);
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(214, 1, 72, 0.993);
  text-align: center;
  outline-color: rgba(214, 1, 72);
}

.otp-input:focus-visible {
  outline-color: rgba(214, 1, 72);
  border: 1px solid rgba(214, 1, 72, 0.993);
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.redBorder >>> fieldset {
  border-color: rgba(250, 0, 33, 0.986);
}
</style>